<template>
  <v-card>
    <v-toolbar
      dark
      color="primary"
      dense
      class="mb-5"
    >
      <v-btn
        icon
        dark
        @click="close"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Sugestão/Reclamação</v-toolbar-title>
      <v-spacer />
    </v-toolbar>
    <v-row
      justify="start"
      no-gutters
      class="pa-2"
    >
      <v-col
        class="d-flex pr-5"
        lg="4"
        md="12"
        sm="12"
      >
        <v-text-field
          v-model="suggestion.user"
          label="Usuário"
          disabled
        />
      </v-col>
      <v-col
        class="d-flex pr-5"
        lg="4"
        md="12"
        sm="12"
      >
        <v-text-field
          v-model="suggestion.type"
          label="Tipo"
          disabled
        />
      </v-col>
      <v-col
        class="d-flex pr-5"
        lg="4"
        md="12"
        sm="12"
      >
        <v-text-field
          v-model="suggestion.category"
          label="Categoria"
          disabled
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        class="baseSuggestion"
      >
        {{ suggestion.text }}
      </v-col>
    </v-row>
    <v-container
      v-for="(answer,i) in answers"
      :key="i"
    >
      <v-row
        v-if="answer.admin === 0 "
        class="respUser"
      >
        <v-col
          cols="6"
        />
        <v-col
          cols="6"
        >
          <v-card-subtitle
            class="userSubtitle"
          >
            {{ suggestion.user }}
            {{ creationDate(answer.creationDate) }}
          </v-card-subtitle>
          <v-card-item>
            {{ answer.text }}
          </v-card-item>
        </v-col>
      </v-row>
      <v-row
        v-else
        class="respAdmin"
      >
        <v-col
          cols="6"
        >
          <v-card-subtitle
            class="admSubtitle"
          >
            {{ creationDate(answer.creationDate) }}
          </v-card-subtitle>
          <v-card-item>
            {{ answer.text }}
          </v-card-item>
        </v-col>
      </v-row>
    </v-container>
    <v-row
      class="textField"
    >
      <v-text-field
        v-model="answerText.text"
        label="Enviar Resposta"
        outlined
        height="1"
        @keyup.enter="submit()"
      />
      <v-btn
        x-large
        color="secondary"
        elevation="2"
        depressed
        class="btnEnviar"
        @click="submit()"
      >
        Enviar
      </v-btn>
    </v-row>
  </v-card>
</template>

<script>
import { postAnswer, getAnswerByIdSuggestion } from '../../../services/answer-service';

export default {
  name: 'ViewSuggestion',
  props: {
    populateWith: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      suggestion: {},
      answers: {},
      answerText: {
        suggestionId: null,
        text: null,
        admin: 1,
      },

    };
  },
  async created() {
    if (this.populateWith.id) {
      this.suggestion = { ...this.populateWith };
    }
    const data = await getAnswerByIdSuggestion(this.suggestion.id);
    this.answers = data;
  },
  methods: {
    close() {
      this.$emit('close-dialog');
    },
    async submit() {
      try {
        this.loading = true;
        this.answerText.suggestionId = this.suggestion.id;
        await postAnswer({ ...this.answerText });
        this.answerText.text = null;
        this.$toast.success('Sugestão/Reclamação cadastrada com sucesso');
        this.loading = false;
        const data = await getAnswerByIdSuggestion(this.suggestion.id);
        this.answers = data;
      } catch (e) {
        this.$handleHttpError(e);
        this.loading = false;
      }
    },
    creationDate(date) {
      const fixDate = new Date(date).toLocaleString('pt-BR');
      return fixDate;
    },
  },
};
</script>
<style >
  .titulo{
    display: flex;
    justify-content: center;
  }
  .userSubtitle{
    text-align: right;
    }
  .respUser{
    text-align: right;
  }
  .admSubtitle{
    text-align: left;
  }
  .respAdmin{
    text-align: left;
  }
  .textField{
    margin: 0px 15px 0px;

    justify-content: space-around;
  }
  .linhaUsuario{
    justify-content: right;
  }
  .btnEnviar{
    margin-left: 15px;
  }
  .baseSuggestion{
    text-align: center;
  }
</style>
